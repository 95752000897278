
  import { Brand, ComponentValue, Model, Version, Year } from '@/entities/vehicle'
  import { Component, Emit, Prop, Watch } from 'vue-property-decorator'
  import FormTitle from '@/components/forms/FormTitle.vue'
  import GFormSlot from '@/components/forms/GFormSlot.vue'
  import { RULES } from '@/components/forms'
  import { FilesProcess } from '@/components/forms/view/FilesProcess'
  import dayjs from 'dayjs'

@Component({
  components: { GFormSlot, FormTitle },
})
  export default class managerVersionView extends FilesProcess {
  @Prop({ type: Boolean, default: false }) readonly value!: boolean;
  @Prop({ type: Array, default: [] }) readonly versions!: Version[];
  @Prop({ type: Array, default: [] }) readonly years!: Year[];
  @Prop({ type: Object, default: null }) readonly model!: Model;
  @Prop({ type: Object, default: null }) readonly brand!: Brand;

  width = 800
  messageYear = ''
  messageVersion = ''
  rule = RULES.isRequired

  form = {
    yearStart: null,
    yearEnd: null,
    version: null,
    fuel: null,
    bodyWork: null,
    transmission: null,
    traction: null,
    doors: null,
    cylinder: null,
    power: null,
    basicEquipment: null,
    comfortEquipment: null,
    securityEquipment: null,
    exteriorEquipment: null,
  }

  fuels: ComponentValue[] = []
  bodyWorks: ComponentValue[] = []
  transmissions: ComponentValue[] = []
  traction: ComponentValue[] = []
  doors: ComponentValue[] = []
  basicEquipment: ComponentValue[] = []
  comfortEquipment: ComponentValue[] = []
  securityEquipment: ComponentValue[] = []
  exteriorEquipment: ComponentValue[] = []
  cylinder = null
  power = null
  loading = false

  async mounted () {
    this.form.yearStart = dayjs().year()
    this.form.yearEnd = dayjs().year()
    this.fuels = await this.fetchData({
      query: { name: 'find', model: 'ComponentValue' },
      filter: {
        component: {
          name: { _eq: 'Combustible' },
        },
      },
    })

    this.bodyWorks = await this.fetchData({
      query: { name: 'find', model: 'ComponentValue' },
      filter: {
        component: {
          name: { _eq: 'Carrocería' },
        },
      },
    })

    this.transmissions = await this.fetchData({
      query: { name: 'find', model: 'ComponentValue' },
      filter: {
        component: {
          name: { _eq: 'Transmisión' },
        },
      },
    })

    this.traction = await this.fetchData({
      query: { name: 'find', model: 'ComponentValue' },
      filter: {
        component: {
          name: { _eq: 'Tracción' },
        },
      },
    })

    this.doors = await this.fetchData({
      query: { name: 'find', model: 'ComponentValue' },
      filter: {
        component: {
          name: { _eq: 'Nº de puertas' },
        },
      },
    })

    this.basicEquipment = await this.fetchData({
      query: { name: 'find', model: 'ComponentValue' },
      filter: {
        _and: [
          {
            component: {
              name: { _eq: 'Equipamiento básico' },
            },
          },
          { metadata: { _is_null: true } },
        ],
      },
    })

    this.comfortEquipment = await this.fetchData({
      query: { name: 'find', model: 'ComponentValue' },
      filter: {
        component: {
          name: { _eq: 'Equipamiento comfort' },
        },
      },
    })

    this.securityEquipment = await this.fetchData({
      query: { name: 'find', model: 'ComponentValue' },
      filter: {
        component: {
          name: { _eq: 'Equipamiento seguridad' },
        },
      },
    })

    this.exteriorEquipment = await this.fetchData({
      query: { name: 'find', model: 'ComponentValue' },
      filter: {
        component: {
          name: { _eq: 'Equipamiento exterior' },
        },
      },
    })

    const cylinder = await this.fetchData({
      query: { name: 'find', model: 'Component' },
      filter: {
        name: { _eq: 'Cilindrada' },
      },
    })

    this.cylinder = cylinder[0].id

    const power = await this.fetchData({
      query: { name: 'find', model: 'Component' },
      filter: {
        name: { _eq: 'Potencia' },
      },
    })
    this.power = power[0].id
  }

  @Watch('form', { immediate: true, deep: true })
  onFormChange (val: any) {
    if (val.yearStart && val.yearEnd && val.yearEnd < val.yearStart) {
      this.messageYear = 'El año de inicio debe ser menor al año de fin'
    } else {
      this.messageYear = ''
    }

    if (val.version?.length > 0) {
      const version = this.versions.find((item: any) => item.name.toLowerCase() === val.version.toLowerCase())
      if (version) {
        this.messageVersion = 'La versión ya existe'
      } else {
        this.messageVersion = ''
      }
    } else {
      this.messageVersion = ''
    }
  }

  @Emit('input')
  cancel () {
    this.form = {
      yearStart: dayjs().year(),
      yearEnd: dayjs().year(),
      version: null,
      fuel: null,
      bodyWork: null,
      transmission: null,
      traction: null,
      doors: null,
      cylinder: null,
      power: null,
      basicEquipment: null,
      comfortEquipment: null,
      securityEquipment: null,
      exteriorEquipment: null,
    }
    return false
  }

  async send () {
    const { messageYear, messageVersion } = this
    if (messageYear?.length || messageVersion?.length) {
      return
    }
    this.loading = true
    const { form } = this

    const basicEquipment = this.buildEquipmentFields(form.basicEquipment)

    const comfort = this.buildEquipmentFields(form.comfortEquipment)

    const security = this.buildEquipmentFields(form.securityEquipment)

    const exterior = this.buildEquipmentFields(form.exteriorEquipment)
    const fields = this.generateFieldsArray(form, basicEquipment, comfort, security, exterior)

    const version = await this.pushData({
      model: 'Version',
      fields,
    })
    this.loading = false
    this.$emit('update', version)
    this.cancel()
  }

  buildEquipmentFields (items) {
    return items?.map((item: any) => ({
      id_component: item.component.id,
      id_component_value: item.id,
      validated: true,
    })) || []
  }

  generateFieldsArray (form: any, basicEquipment: any[], comfort: any[], security: any[], exterior: any[]) {
    const { power, cylinder, model } = this
    const fieldsArray = []
    const fields = {
      name: form.version.toUpperCase(),
      id_model: model.id,
      years: {
        data: null,
      },
    }

    for (let year = form.yearStart; year <= form.yearEnd; year++) {
      const attr = [{
        id_component: power,
        value: form.power,
        validated: true,
      },
        {
          id_component: cylinder,
          value: form.cylinder,
          validated: true,
        }].filter(item => item.value)
      fieldsArray.push({
        id_year: year,
        attributes: {
          data: [
            ...attr,
            {
              id_component: form.bodyWork.component.id,
              id_component_value: form.bodyWork.id,
              validated: true,
            },
            {
              id_component: form.fuel.component.id,
              id_component_value: form.fuel.id,
              validated: true,
            },
            {
              id_component: form.transmission.component.id,
              id_component_value: form.transmission.id,
              validated: true,
            },
            {
              id_component: form.traction.component.id,
              id_component_value: form.traction.id,
              validated: true,
            },
            {
              id_component: form.doors.component.id,
              id_component_value: form.doors.id,
              validated: true,
            },
            ...basicEquipment,
            ...comfort,
            ...security,
            ...exterior,
          ],
        },
      })
    }
    fields.years.data = fieldsArray
    return fields
  }

  async checkVersion () {
    const { version } = this.form

    if (version) {
      const versionExists = this.versions.find((item: any) => item.name.toLowerCase() === version.toLowerCase())
      if (versionExists) {
        this.messageVersion = 'La versión ya existe'
      } else {
        this.messageVersion = ''
      }
    }
  }
  }
