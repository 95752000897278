
  import { Component, Prop, Vue } from 'vue-property-decorator'
  import { Icon } from '@/entities/settings'

@Component
  export default class Panel extends Vue {
  @Prop({ type: String, required: true }) title!: string;
  @Prop({ type: [String, Object] }) icon!: string | Icon;
  @Prop({ type: String }) color!: string;
  @Prop({ type: String }) tooltip!: string;
  @Prop({ type: Boolean }) disabled!: boolean;
  @Prop({ type: Boolean }) open!: boolean;
  @Prop({ type: Object, default: () => ({ textColor: 'white', small: true }) }) chip?: Record<string, any>;
  }
