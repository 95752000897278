
  import { Component, Watch } from 'vue-property-decorator'
  import { CustomAttributes, Generation, VersionYear, Year } from '@/entities/vehicle'
  import { FilesProcess } from '@/components/forms/view/FilesProcess'
  import BaseSiiTable from '@/components/dataTables/sii/BaseSiiTable.vue'
  import { plainToInstance } from 'class-transformer'
  import { Auto } from '@/entities/public'
  import AutoContent from '@/components/toolkit/details/row/expandable/auto/content.vue'
  import FieldTitle from '@/components/forms/fields/FieldTitle.vue'
  import GFiles from '@/components/core/files/GFiles.vue'
  import { RULES } from '@/components/forms'
  import { mapActions, mapGetters, mapMutations } from 'vuex'
  import { Cursor } from '@/entities/public/Resource/interfaces'
  import { Person } from '@/entities/persons'
  import GAlert from '@/components/core/alert/GAlert.vue'
  import GCostField from '@/components/core/input/GCostField.vue'
  import { isValidNumber } from '@/utils/general'
  import ManagerVersionView from '@/components/forms/versionYear/managerVersionView.vue'
  import dayjs from 'dayjs'

@Component({
  components: { GCostField, ManagerVersionView, GAlert, GFiles, FieldTitle, BaseSiiTable, AutoContent },
  methods: {
    ...mapActions('resources/datatables', ['findVersionYearAggregates', 'updateMileagePrice']),
    ...mapMutations('resources/datatables', ['setData']),
    ...mapActions('resources', ['setCursorSize', 'setCursorPage']),
  },
  computed: {
    ...mapGetters('resources', ['cursor']),
  },
})
  export default class VersionManagerView extends FilesProcess {
  declare $refs: {
    tableSii: BaseSiiTable,
    form: HTMLFormElement,
    formVersion: HTMLFormElement,
  };

  updateMileagePrice!: (payload) => Promise<any>
  findVersionYearAggregates!: (payload) => Promise<any>;
  setData!: (payload) => void;
  setCursorSize!: (size: number) => void
  setCursorPage!: (page: number) => void

  private cursor!: Cursor
  rule = RULES.isRequired
  brand = {
    openAlert: false,
    title: '',
    message: '',
    edit: null,
    new: '',
  }

  brandSelected = null

  model = {
    openAlert: false,
    title: '',
    message: '',
    edit: null,
    new: '',
  }

  modelSelected = null

  version = {
    openAlert: false,
    title: '',
    message: '',
    edit: null,
    new: '',
  }

  aliasBrand = null
  aliasModel = null
  idProcessBrand = null
  idProcessModel = null
  idProcessMileage = null
  photoMileageProperties = {
    properties: {
      accept: null,
      multiple: null,
      fileTypeId: null,
      name: null,
    },
  }

  photo = null
  idChileautos: number = null
  updateModel = false
  updateBrand = false
  linkRule = RULES.isUrl
  mountedView = true
  items = []
  brands = []
  models = []
  versions = []
  vehicleGama = []
  rotationIndex = []
  fuels = []
  transmissions = []
  photos = {
    properties: {
      accept: null,
      multiple: null,
      fileTypeId: null,
      name: null,
    },
  }

  pdf = {
    properties: {
      accept: null,
      multiple: null,
      fileTypeId: null,
      name: null,
    },
  }

  form = {
    pdf: null,
    photo: null,
    linkModel: null,
    vehicleGama: null,
    linkBrand: null,
    rotationIndex: null,
    aliasModel: null,
    aliasBrand: null,
    amount: '',
    amountFinanced: '',
    backupPhoto: [],
    link: '',
    discontinued: false,
    modelPriceLink: '',
  }

  idProcess = null
  open = false
  loadingSend = false
  loading = false
  person: Person | null = null
  years: Year[] = [];
  auto: Auto = null
  formData = {
    startYear: null,
    endYear: null,
    brand: '',
    model: '',
    version: [],
    update: false,
    rotation: false,
    fuel: '',
    transmission: [],
    withoutPrice: false,
    withoutPhoto: false,
    discontinued: null,
    modelDiscontinued: null,
  }

  formVersionData = {
    link: '',
    amount: '',
    amountFinanced: '',
    backupPhoto: [],
  }

  generation: Generation | null = null;
  headers: Record<string, any>[] = [
    { text: 'ID', value: 'id', sortable: false, align: 'center' },
    { text: '', value: '', sortable: false },
    { text: '', value: '', sortable: false, width: '130px', align: 'center' },
    { text: 'Marca', value: '', width: '150px', sortable: false, align: 'center' },
    { text: 'Modelo', value: '', width: '180px', sortable: false, align: 'center' },
    { text: 'Año', value: 'year', width: '100px', sortable: true },
    { text: 'Versión', value: 'version', width: '200px', sortable: false, align: 'center' },
    { text: '0 KM', value: '', align: 'center', width: '700px', sortable: false },
    { text: 'Rotación', value: '', align: 'center', width: '420px', sortable: false },
    { text: 'Enviar', value: '', align: 'center', sortable: false },
  ];

  versionYears: VersionYear[] = []

  async mounted () {
    await this.loadInfo()

    const fileInfo = await this.fetchData({
      query: { name: 'find', model: 'FileParameter' },
      filter: { process: { table_name: { _eq: 'version_year' } } },
    })

    const photos = fileInfo.find(fileParameter => fileParameter.name === 'version_model_photo')

    const pdf = fileInfo.find(fileParameter => fileParameter.fileType.isPdf)

    const mileageFileInfo = await this.fetchData({
      query: { name: 'find', model: 'FileParameter' },
      filter: { process: { table_name: { _eq: 'mileage_price' } } },
    })

    const mileagePhotos = mileageFileInfo.find(fileParameter => fileParameter.fileType.isPhoto)

    await this.setProcess()

    const enterpriseChileautos = await this.fetchData({
      query: { name: 'find', model: 'Person' },
      filter: { alias: { _ilike: '%Chileautos%' } },
    })
    this.idChileautos = enterpriseChileautos?.[0]?.id

    const { query } = this.$route

    this.formData.startYear = parseInt(query?.startYear as string)
    this.formData.endYear = parseInt(query?.endYear as string)
    this.formData.brand = query?.brand as string
    this.formData.model = query?.model as string
    this.formData.version = query?.version as Record<number, any>[]
    const transmission = query?.transmission as Record<number, any>[]
    this.formData.transmission = typeof transmission === 'string' ? [transmission] : transmission
    this.formData.fuel = query?.fuel as string
    this.mountedView = false

    this.setFileProperties('photos', photos)
    this.setFileProperties('pdf', pdf)
    this.setFileProperties('photoMileageProperties', mileagePhotos)
  }

  async loadInfo () {
    this.person = await this.fetchData({
      query: { name: 'find', model: 'Person' },
      filter: { alias: { _eq: 'Chileautos' } },
    })

    this.years = await this.fetchData({
      query: { name: 'find', model: 'Year' },
    })

    this.brands = await this.fetchData({
      query: { name: 'find', model: 'Brand', order: { name: 'asc' } },
    })

    this.rotationIndex = await this.fetchData({
      query: { name: 'find', model: 'RotationIndex', order: { value: 'asc' } },
    })

    this.vehicleGama = await this.fetchData({
      query: { name: 'find', model: 'VehicleGama' },
    })

    this.transmissions = await this.fetchData({
      query: { name: 'find', model: 'ComponentValue' },
      filter: {
        component: {
          name: { _eq: 'Transmisión' },
        },
      },
    })

    this.fuels = await this.fetchData({
      query: { name: 'find', model: 'ComponentValue' },
      filter: {
        component: {
          name: { _eq: 'Combustible' },
        },
      },
    })
  }

  setFileProperties (prop, file) {
    if (file) {
      this[prop].properties = file
      this[prop].properties.accept = file?.fileType?.mimes
      this[prop].properties.multiple = file?.multiple
      this[prop].properties.fileTypeId = file?.fileType?.id
      this[prop].properties.name = file?.name
    }
  }

  async setProcess () {
    const process = await this.fetchData({
      query: { name: 'find', model: 'Process' },
      filter: { table_name: { _eq: 'version_year' } },
    })
    this.idProcess = process?.[0]?.id
    const processBrand = await this.fetchData({
      query: { name: 'find', model: 'Process' },
      filter: { table_name: { _eq: 'brand' } },
    })
    this.idProcessBrand = processBrand?.[0]?.id

    const processModel = await this.fetchData({
      query: { name: 'find', model: 'Process' },
      filter: { table_name: { _eq: 'model' } },
    })
    this.idProcessModel = processModel?.[0]?.id

    const processMileage = await this.fetchData({
      query: { name: 'find', model: 'Process' },
      filter: { table_name: { _eq: 'mileage_price' } },
    })

    this.idProcessMileage = processMileage?.[0]?.id
  }

  async cleanFilter (filter) {
    const { formData: { withoutPrice, withoutPhoto, rotation } } = this

    const isValidGteLte = value => !isNaN(value) && value !== null
    const isValidValue = value => Boolean(value?.length)

    filter._and = filter._and.reduce((acc, condition) => {
      const key = Object.keys(condition)[0]

      if (key === 'id_year' || key === 'years') {
        const { _gte, _lte } = key === 'id_year' ? condition[key] : condition[key].id_year
        const validGte = isValidGteLte(_gte)
        const validLte = isValidGteLte(_lte)

        if (validGte || validLte) {
          if (key === 'id_year') {
            acc.push({
              [key]: {
                ...(validGte ? { _gte } : {}),
                ...(validLte ? { _lte } : {}),
              },
            })
          } else {
            acc.push({
              [key]: {
                id_year: {
                  ...(validGte ? { _gte } : {}),
                  ...(validLte ? { _lte } : {}),
                },
              },
            })
          }
        }
      } else if (key === 'id_rotation_index' && rotation) {
        acc.push(condition)
      } else {
        let path = condition[key]
        let valid = true

        while (path && typeof path === 'object' && !('_eq' in path || '_in' in path)) {
          const nextKey = Object.keys(path)[0]
          path = path?.[nextKey]
          if (path === undefined) {
            valid = false
            break
          }
        }
        if (valid && (isValidValue(path?._eq) || isValidValue(path?._in))) {
          acc.push(condition)
        }
      }

      return acc
    }, [])

    if (withoutPrice) {
      filter._and.push({
        _not: {
          mileage_prices: {
            _and: {
              date_to: { _is_null: true },
              mileage_from: { _eq: 0 },
              mileage_to: { _eq: 0 },
            },
          },
        },
      })
    }

    if (withoutPhoto) {
      const versionWithPhotos = await this.fetchData({
        query: { name: 'find', model: 'FileProcess' },
        filter: {
          parameter: {
            _and: [{ name: { _eq: 'version_model_photo' } }, { process: { table_name: { _eq: 'version_year' } } }],
          },
        },
        force: true,
      })

      const versionWithPhotosIds = versionWithPhotos.map(({ idProcessRecord }) => idProcessRecord)

      filter._and.push({
        id: {
          _nin: versionWithPhotosIds,
        },
      })
    }

    return filter._and?.length ? filter : {}
  }

  isObjectEmptyOrInvalid (obj) {
    for (const key in obj) {
      if (obj.hasOwnProperty(key)) {
        const value = obj[key]
        if (!value) return true
        const keys = Object.keys(value)
        if (typeof value === 'object' && keys.length === 1 && (value?._eq === '' || value?._eq === null || value?._lte === '' || value?._lte === null || value?._gte === '' || value?._gte === null)) {
          return true
        } else {
          if (typeof value === 'object' && this.isObjectEmptyOrInvalid(value)) {
            return true
          }
        }
      }
    }
    return false
  }

  async searchVersionYear (cursor) {
    const { formData: val } = this

    if (!val.startYear && !val.endYear && !val.brand) {
      return
    }

    this.loading = true

    const filter = await this.cleanFilter({
      _and: [
        { id_year: { _gte: val?.startYear, _lte: val?.endYear } },
        {
          version: { model: { brand: { name: { _eq: val?.brand } } } },
        },
        {
          version: { model: { name: { _eq: val?.model } } },
        },
        {
          version: { id: { _in: val?.version } },
        },
        { attributes: { component_value: { value: { _eq: val?.fuel } } } },
        { attributes: { component_value: { value: { _in: val?.transmission } } } },
        {
          id_rotation_index: { _is_null: Boolean(val?.rotation) },
        },
      ],
    })

    const filterBrand = this.buildBrandFilter()

    this.brands = await this.fetchData({
      query: { name: 'find', model: 'Brand', order: { name: 'asc' } },
      filter: filterBrand,
      force: true,
    })
    this.setRouteQuery()
    await this.findVersions(filter, cursor)
  }

  setRouteQueryYears (query) {
    const { formData: { startYear, endYear } } = this

    if ((!query?.startYear || (startYear && query?.startYear.toString() !== startYear?.toString())) && isValidNumber(startYear)) {
      const query = Object.assign({}, this.$route.query, { startYear })
      this.$router.replace({ query }).catch(() => {
      })
    } else if (query?.startYear) {
      delete query.startYear
      this.$router.replace({ query }).catch(() => {
      })
    }

    if ((!query?.endYear || (query?.endYear.toString() !== endYear?.toString())) && isValidNumber(endYear)) {
      const query = Object.assign({}, this.$route.query, { endYear })
      this.$router.replace({ query }).catch(() => {
      })
    } else if (query?.endYear) {
      delete query.endYear
      this.$router.replace({ query }).catch(() => {
      })
    }
  }

  setRouteQueryBrand (query) {
    const { formData: { brand } } = this
    if (!brand?.length) {
      if (query?.brand) {
        delete query.brand
        this.$router.replace({ query }).catch(() => {
        })
      }
      if (query?.model) {
        delete query.model
        this.$router.replace({ query }).catch(() => {
        })
      }
      if (query?.version) {
        delete query.version
        this.$router.replace({ query }).catch(() => {
        })
      }
    } else if ((brand && !query?.brand) || (query?.brand !== brand)) {
      const query = Object.assign({}, this.$route.query, { brand: this.formData?.brand })
      this.$router.replace({ query }).catch(() => {
      })
    }
  }

  setRouteQueryModel (query) {
    const { formData: { model } } = this
    if (!model?.length) {
      if (query?.model) {
        delete query.model
        this.$router.replace({ query }).catch(() => {
        })
      }
      if (query?.version) {
        delete query.version
        this.$router.replace({ query }).catch(() => {
        })
      }
    } else if ((model && !query?.model) || (query?.model !== model)) {
      const query = Object.assign({}, this.$route.query, { model: this.formData?.model })
      this.$router.replace({ query }).catch(() => {
      })
    }
  }

  setRouteQueryVersion (query) {
    const { formData: { version } } = this
    if (!version?.length) {
      if (query?.version) {
        delete query.version
        this.$router.replace({ query }).catch(() => {
        })
      }
    } else if ((version && !query?.version) || (query?.version !== version)) {
      const query = Object.assign({}, this.$route.query, { version: this.formData?.version })
      this.$router.replace({ query }).catch(() => {
      })
    }
  }

  setRouteQueryAttributes (query) {
    const { formData: { fuel, transmission } } = this

    if (!fuel?.length) {
      if (query?.fuel) {
        delete query.fuel
        this.$router.replace({ query }).catch(() => {
        })
      }
    } else if ((fuel && !query?.fuel) || (query?.fuel !== fuel)) {
      const query = Object.assign({}, this.$route.query, { fuel: this.formData?.fuel })
      this.$router.replace({ query }).catch(() => {
      })
    }
    if (!transmission?.length) {
      if (query?.transmission) {
        delete query.transmission
        this.$router.replace({ query }).catch(() => {
        })
      }
    } else if ((transmission && !query?.transmission) || (query?.transmission !== transmission)) {
      const query = Object.assign({}, this.$route.query, { transmission: this.formData?.transmission })
      this.$router.replace({ query }).catch(() => {
      })
    }
  }

  setRouteQuery () {
    const query = { ...this.$route.query }

    this.setRouteQueryYears(query)
    this.setRouteQueryBrand(query)
    this.setRouteQueryModel(query)
    this.setRouteQueryVersion(query)
    this.setRouteQueryAttributes(query)
  }

  buildBrandFilter () {
    const { formData: val } = this
    const filterBrand = {
      models: { versions: { years: { id_year: { _gte: val.startYear, _lte: val.endYear } } } },
    }

    if (!filterBrand.models.versions.years.id_year._gte) {
      delete filterBrand.models.versions.years.id_year._gte
    }

    if (!filterBrand.models.versions.years.id_year._lte) {
      delete filterBrand.models.versions.years.id_year._lte
    }

    if (!filterBrand.models.versions.years.id_year._gte && !filterBrand.models.versions.years.id_year._lte) {
      delete filterBrand.models
    }
    return filterBrand
  }

  async findCustomAttributes (versionYears) {
    const versionYearIds = versionYears.map(({ id }) => id)

    const customAttribute = await this.findAttributes(versionYearIds)

    versionYears.forEach(version => {
      const foundAttribute = customAttribute.find(versionYear => versionYear.id === version.id)

      version.customAttributes = plainToInstance(CustomAttributes, foundAttribute)
    })

    return versionYears
  }

  async findVersions (filter, cursor) {
    this.loading = true
    this.versionYears = []

    const versions = await this.fetchData({
      query: {
        name: 'find',
        model: 'VersionYear',
        order: {
          id_year: 'asc',
        },
      },
      filter,
      limit: cursor.size,
      offset: (cursor.page - 1) * cursor.size,
      force: true,
    })
    const versionFixed = await this.findCustomAttributes(versions)

    this.versionYears = versionFixed.sort(this.compareVersions)
    const { versionYears: { aggregate: { count } } } = await this.findVersionYearAggregates({ aggregate: filter })

    this.setData({ total: count, records: this.versionYears })
    this.loading = false
  }

  compareVersions (v1, v2) {
    if (v1.version.model.brand.name < v2.version.model.brand.name) return -1
    if (v1.version.model.brand.name > v2.version.model.brand.name) return 1

    if (v1.version.model.name < v2.version.model.name) return -1
    if (v1.version.model.name > v2.version.model.name) return 1

    if (v1.year.id < v2.year.id) return -1
    if (v1.year.id > v2.year.id) return 1

    if (v1.version.name < v2.version.name) return -1
    if (v1.version.name > v2.version.name) return 1

    return 0
  }

  get watchBrand () {
    const { formData: { brand, startYear, endYear }, updateBrand } = this

    return {
      startYear,
      endYear,
      brand,
      updateBrand,
    }
  }

  @Watch('watchBrand', { immediate: true, deep: true })
  async onBrandChange (val) {
    if (!val.brand) return
    this.models = []
    this.versions = []
    this.formData.modelDiscontinued = null
    const filterModel = this.buildModelFilter()

    this.models = await this.fetchData({
      query: {
        name: 'find',
        model: 'Model',
        order: {
          name: 'asc',
        },
      },
      filter: filterModel,
      force: true,
    })

    const match = this.models.find(model => model.name === this.formData.model)
    if (!match) {
      this.formData.model = ''
    }
  }

  buildModelFilter () {
    const { formData: val } = this
    const filterModel = {
      _and: [
        { brand: { name: { _eq: val?.brand } } },
        {
          versions: {
            years: {
              id_year: {
                _gte: this.formData.startYear,
                _lte: this.formData.endYear,
              },
            },
          },
        }],
    }

    if (!filterModel._and[1].versions.years.id_year._gte) {
      delete filterModel._and[1].versions.years.id_year._gte
    }

    if (!filterModel._and[1].versions.years.id_year._lte) {
      delete filterModel._and[1].versions.years.id_year._lte
    }

    if (!filterModel._and[1].versions.years.id_year._gte && !filterModel._and[1].versions.years.id_year._lte) {
      filterModel._and.splice(1, 1)
    }
    return filterModel
  }

  get watchModel () {
    const { formData: { model, endYear, startYear }, updateModel } = this

    return {
      startYear,
      endYear,
      model,
      updateModel,
    }
  }

  @Watch('watchModel', { immediate: true, deep: true })
  async onModelChange (val) {
    const { mountedView } = this
    if (mountedView || !val.model || !this.formData.brand) return

    this.versions = []
    this.formData.modelDiscontinued = null

    const filter = await this.cleanFilter({
      _and: [
        { model: { name: { _eq: val.model } } },
        { model: { brand: { name: { _eq: this.formData?.brand } } } },
      ],
    })
    const filteredYears = this.buildYearFilter(this.formData)
    filter._and.push(...(filteredYears ? [filteredYears] : []))

    const findKey = filter?._and?.map(item => Object.keys(item)[0])?.find(key => key === '_not')

    if (findKey) {
      filter._and = filter._and.filter(item => Object.keys(item)[0] !== '_not')
    }

    this.versions = await this.fetchData({
      query: {
        name: 'find',
        model: 'Version',
        order: {
          name: 'asc',
          id: 'asc',
        },
      },
      filter,
      force: true,
    })

    const matches = this.versions?.filter(version => this.formData.version?.includes(version.id)) || []
    this.formData.version = matches?.map(({ id }) => id)
  }

  get watchVersion () {
    const { formData: { version, endYear, startYear } } = this

    return {
      startYear,
      endYear,
      version,
    }
  }

  get records () {
    const { versionYears } = this

    return versionYears || []
  }

  async details (item: Record<string, any>) {
    this.auto = plainToInstance(Auto, {})
    this.auto.generation = plainToInstance(Generation, {})
    if (item) {
      this.auto.version = item as VersionYear
      if (item?.generations?.length === 1) {
        this.auto.generation = await this.fetchData({
          query: {
            name: 'fetch',
            model: 'Generation',
            params: { id: item.generations[0].id },
          },
        })
        this.generation = this.auto.generation
      } else {
        if (item?.attributes?.length) {
          this.auto.generation.attributes = item.attributes
        } else {
          const attributes = await this.fetchData({
            query: {
              name: 'find',
              model: 'Attribute',
            },
            filter: {
              id_version_year: { _eq: item.id },
            },
          })

          if (attributes.length) {
            this.auto.generation.attributes = attributes
          } else {
            const generations = await this.fetchData({
              query: { name: 'find', model: 'Generation' },
              filter: { version_year: { id_version: { _eq: item.version.id }, id_year: { _eq: item.year.id - 1 } } },
            })

            if (generations.length === 1) {
              this.auto.generation = await this.fetchData({
                query: {
                  name: 'fetch',
                  model: 'Generation',
                  params: { id: generations[0].id },
                },
              })

              this.generation = this.auto.generation
            } else {
              this.auto.version.generations = generations
            }
          }
        }
      }
      this.open = true
    }
  }

  async getVersionYears () {
    const filter = await this.cleanFilter({
      _and: [
        {
          version: { model: { name: { _eq: this.formData.model } } },
        },
        {
          version: { model: { brand: { name: { _eq: this.formData.brand } } } },
        },
        {
          version: { id: { _in: this.formData.version } },
        },
        { attributes: { component_value: { value: { _eq: this.formData?.fuel } } } },
        { attributes: { component_value: { value: { _in: this.formData?.transmission } } } },
        {
          id_year: {
            _gte: this.formData.startYear,
            _lte: this.formData.endYear,
          },
        },
      ],
    })

    return this.fetchData({
      query: {
        name: 'find',
        model: 'VersionYear',
      },
      filter,
    })
  }

  handleValidation () {
    const formValidation = this.$refs?.form && !this.$refs?.form?.validate()
    const formVersionValidation = this.$refs?.formVersion && !this.$refs?.formVersion?.validate()
    return Boolean(formValidation) || Boolean(formVersionValidation)
  }

  async callOtherFunctions (versionYears) {
    await this.sendPrices(versionYears)
    await this.setBrandInfo()
    await this.sendFiles(versionYears)
  }

  async sendInfo () {
    if (this.handleValidation()) {
      return
    }

    const {
      form,
      models,
      formData,
      isModelFilled,
      isLinkValid,
    } = this
    this.loadingSend = true
    const versionYears = await this.findCustomAttributes(await this.getVersionYears())

    await this.callOtherFunctions(versionYears)
    const model = models.find(model => model.name === formData.model)

    if (form.aliasModel && isModelFilled && this.aliasModel?.name !== form.aliasModel) {
      const fields = {
        id: this.aliasModel?.id,
        name: form.aliasModel,
        id_process_record: model.id,
        id_process: this.idProcessModel,
        id_person: this.idChileautos,
      }
      await this.pushData({
        model: 'Alias',
        fields,
      })
    }

    if (isLinkValid(form.linkModel) && isModelFilled) {
      await this.pushData({
        model: 'Model',
        fields: {
          id: model.id,
          official_web: form.linkModel,
        },
      })
    }

    if (isLinkValid(form.modelPriceLink) && isModelFilled) {
      await this.pushData({
        model: 'Model',
        fields: {
          id: model.id,
          price_link: form.modelPriceLink,
        },
      })
    }

    if (isModelFilled) {
      await this.sendRotationIndex(versionYears)
    }

    this.formData.update = !this.formData.update
    this.versionYears = []
    this.loadingSend = false
    await this.discontinuedVersion()
    await this.searchVersionYear(this.cursor)
  }

  async discontinuedVersion () {
    const { formData: { version, discontinued, modelDiscontinued }, versions } = this

    if (discontinued !== null && modelDiscontinued === null) {
      await this.sendVersionInfoDiscontinued(version, discontinued)
    } else if (modelDiscontinued !== null) {
      await this.sendVersionInfoDiscontinued(versions?.map(({ id }) => id), modelDiscontinued)
    }
  }

  async sendVersionInfoDiscontinued (versions, discontinued) {
    if (!versions?.length) return

    await Promise.all(versions.map(async version => {
      const fields = {
        id: version,
        discontinued,
      }

      await this.pushData({
        model: 'Version',
        fields,
      })
    }))

    await this.onModelChange({
      startYear: this.formData.startYear,
      endYear: this.formData.endYear,
      model: this.formData.model,
      updateModel: this.updateModel,
    })
  }

  async sendRotationIndex (versionYears) {
    const { form: { rotationIndex } } = this

    if (!rotationIndex) return

    await Promise.all(versionYears.map(async versionYear => {
      if (versionYear?.hasDirectAttributes) {
        await this.pushData({
          model: 'VersionYear',
          fields: {
            id: versionYear.id,
            id_rotation_index: rotationIndex,
          },
        })
      } else if (!versionYear?.hasMultipleSKUs) {
        await this.pushData({
          model: 'Generation',
          fields: {
            id: versionYear.attrs[0].id,
            id_rotation_index: rotationIndex,
          },
        })
      } else {
        const attrs = this.filterAttributes(versionYear.attrs)

        if (!attrs.length) return
        await Promise.all(attrs.map(async (item: any) => {
          await this.pushData({
            model: 'Generation',
            fields: {
              id: item.id,
              id_rotation_index: rotationIndex,
            },
          })
        }))
      }
    }))
  }

  async setBrandInfo () {
    const { formData, brands, isBrandFilled, isLinkValid, form } = this

    const brand = brands.find(brand => brand.name === formData.brand)
    if (isLinkValid(form.linkBrand) && isBrandFilled) {
      await this.pushData({
        model: 'Brand',
        fields: {
          id: brand.id,
          official_web: form.linkBrand,
        },
      })
    }

    if (form.aliasBrand && isBrandFilled && this.aliasBrand?.name !== form.aliasBrand) {
      const fields = {
        id: this.aliasBrand?.id,
        name: form.aliasBrand,
        id_process_record: brand.id,
        id_process: this.idProcessBrand,
        id_person: this.idChileautos,
      }
      await this.pushData({
        model: 'Alias',
        fields,
      })
    }

    if (form.vehicleGama && isBrandFilled) {
      await this.pushData({
        model: 'Brand',
        fields: {
          id: brand.id,
          gama: form.vehicleGama,
        },
      })
    }
  }

  async sendPrices (versionYears) {
    const { displayPriceFields, formVersionData: { link, amount, amountFinanced, backupPhoto } } = this
    if (!displayPriceFields) return

    if (!link?.length && !isValidNumber(amountFinanced) && !isValidNumber(amount)) {
      this.loadingSend = false
      return
    }

    await Promise.all(versionYears.map(async versionYear => {
      if (versionYear?.hasDirectAttributes || !versionYear?.hasMultipleSKUs) {
        const mileagePrice = versionYear.mileagePrices?.find((item: any) => item.mileageFrom === 0 && item.mileageTo === 0 && item.dateTo === null)
        if (isValidNumber(amount) || isValidNumber(amountFinanced)) {
          const fields = {
            id: mileagePrice?.id,
            link,
            amount: parseInt(amount) || 0,
            amountFinanced: parseInt(amountFinanced),
            mileageFrom: 0,
            mileageTo: 0,
            versionYearId: versionYear.id,
            generationId: versionYear?.attrs?.[0]?.id,
          }

          const mileage = await this.updateMileagePrice(fields)

          if (backupPhoto.length) {
            await this.handleFileType(backupPhoto, this.photoMileageProperties, this.idProcessMileage, mileage.id)
          }
        }
      } else {
        await this.handleMultipleSku(versionYear)
      }
    }))

    this.formVersionData.amount = ''
    this.formVersionData.amountFinanced = ''
    this.formVersionData.link = null
    this.formVersionData.backupPhoto = []
    this.loadingSend = false
  }

  filterAttributes (data) {
    const { formData: { fuel, transmission } } = this
    return data.filter(att => {
      if (!fuel && !transmission?.length) return true

      const hasFuel = fuel && att?.fuel?.value === fuel
      const hasTransmission = transmission?.length && transmission.includes(att?.transmission?.value)

      return hasFuel || hasTransmission
    })
  }

  async handleMultipleSku (versionYear) {
    const attrs = this.filterAttributes(versionYear.attrs)
    if (!attrs.length) return

    const { formVersionData } = this
    await Promise.all(attrs.map(async (attr: any) => {
      const mileagePrices = versionYear?.mileagePrices?.filter((item: any) => item.mileageFrom === 0 && item.mileageTo === 0 && item.dateTo === null)
      const mileagePrice = mileagePrices?.find((item: any) => (item?.id && attr?.id && item.idGeneration === attr?.id))

      if (isValidNumber(formVersionData.amount) || isValidNumber(formVersionData.amountFinanced)) {
        const fields = {
          id: mileagePrice?.id,
          link: formVersionData.link,
          amount: parseInt(formVersionData.amount) || 0,
          amountFinanced: parseInt(formVersionData.amountFinanced),
          mileageFrom: 0,
          mileageTo: 0,
          versionYearId: versionYear.id,
          generationId: attr.id,
        }

        const mileage = await this.updateMileagePrice(fields)

        if (formVersionData.backupPhoto.length) {
          await this.handleFileType(this.form.backupPhoto, this.photoMileageProperties, this.idProcessMileage, mileage.id)
        }
      }
    }))
  }

  async sendFiles (versions) {
    const { form, pdf, photos, idProcess } = this

    if (form.photo?.length) {
      await Promise.all(versions.map(async versionYear => {
        await this.handleFileType(form.photo, photos, idProcess, versionYear.id)
      }))
    }

    if (form.pdf?.length) {
      await Promise.all(versions.map(async versionYear => {
        await this.handleFileType(form.pdf, pdf, idProcess, versionYear.id)
      }))
    }
  }

  get displayGenerations () {
    const { auto } = this
    return auto?.version?.generations?.length > 1
  }

  get isBrandFilled () {
    const { formData } = this

    return Boolean(formData?.brand?.length)
  }

  get isModelFilled () {
    const { formData } = this

    return Boolean(formData?.model?.length)
  }

  get isVersionSelected () {
    const { formData } = this

    return Boolean(formData?.version?.length)
  }

  get isOnlyOneVersionSelected () {
    const { formData } = this

    return formData?.version?.length === 1
  }

  isLinkValid (link) {
    return this.linkRule.every(rule => rule(link) === true)
  }

  @Watch('formData.version', { immediate: true, deep: true })
  async onVersionChange (val) {
    if (val?.length === 1) {
      const version = await this.fetchData({
        query: { name: 'find', model: 'Version' },
        filter: {
          id: { _eq: val[0] },
        },
        force: true,
      })

      this.formData.discontinued = version[0].discontinued
    } else {
      this.formData.discontinued = null
    }
  }

  @Watch('generation', { immediate: true })
  async onGenerationChange (val) {
    if (val) {
      this.auto = null
      this.auto = plainToInstance(Auto, {})
      const version = await this.fetchData({
        query: {
          name: 'find',
          model: 'VersionYear',
        },
        filter: {
          generations: {
            id: {
              _eq: val.id,
            },
          },
        },
      })
      this.auto.version = version[0]
      this.auto.generation = val
    }
  }

  @Watch('open', { immediate: true })
  onOpenChange (val) {
    if (!val) {
      this.generation = null
      this.auto = plainToInstance(Auto, {})
    }
  }

  async clearBrand (val) {
    const match = this.brands.find(brand => brand.name === val)
    this.form.linkBrand = match?.official_web || ''

    if (!match) {
      this.formData.brand = ''
      this.formData.model = ''
      this.formData.version = []
      this.models = []
      this.versions = []
      this.form.vehicleGama = ''
      this.brandSelected = null
    }
  }

  async clearModel (val) {
    const match = this.models.find(model => model.name === val)
    this.form.photo = null
    this.form.pdf = null
    if (!match) {
      this.formData.model = ''
      this.formData.version = []
      this.versions = []
      this.form.linkModel = ''
      this.form.aliasModel = ''
    }
  }

  clearVersion (selectedVersions) {
    const validVersionIds = this.versions.map(version => version.id)

    const hasInvalidVersion = selectedVersions.some(versionId => !validVersionIds.includes(versionId))

    if (hasInvalidVersion) {
      this.formData.version = []
    }
  }

  get chileautos () {
    const { person } = this

    return person?.[0]
  }

  editModel (val) {
    if (!val?.length) {
      this.addModel()
      return
    }
    const model = this.models.find(model => model.name === val)
    this.model.openAlert = true
    this.model.new = model.name
    this.model.edit = model
  }

  addModel () {
    this.model.title = `Agrega un modelo a la lista para la marca ${this.formData.brand}`
    this.model.openAlert = true
  }

  async confirmEditModel () {
    if (!this.model.new?.length) {
      this.model.message = 'Debe ingresar un modelo'
      return
    } else if (this.model.new.toUpperCase() === this.model.edit?.name.toUpperCase()) {
      this.model.message = 'Debe ingresar un nombre distinto al actual'
      return
    } else if (this.models.some(model => model.name.toUpperCase() === this.model.new.toUpperCase())) {
      this.model.message = 'Ya existe un modelo con ese nombre'
      return
    } else {
      this.model.message = ''
    }
    this.model.openAlert = false
    this.model.title = 'Al editar el nombre del modelo se verá reflejado en todos los autos asociados'

    const brand = this.brands.find(brand => brand.name === this.formData.brand)
    await this.pushData({
      model: 'Model',
      fields: {
        id: this.model.edit?.id,
        id_brand: brand?.id,
        name: this.model.new.toUpperCase(),
      },
    })
    this.updateBrand = !this.updateBrand
    this.formData.model = this.model.new.toUpperCase()
    this.formData.update = !this.formData.update
  }

  addVersion () {
    this.version = {
      openAlert: true,
      title: `Agrega una versión a la lista para el modelo ${this.formData.model}`,
      message: '',
      edit: null,
      new: '',
    }
  }

  editVersion (val) {
    if (!val?.length) {
      this.addVersion()
      return
    }

    const version = this.versions.find(version => version.id === val[0])

    this.version = {
      openAlert: true,
      title: 'Al editar el nombre de la versión se verá reflejado en todos los autos asociados',
      message: '',
      edit: version,
      new: version.name,
    }
  }

  async confirmEditVersion () {
    if (!this.version.new?.length) {
      this.version.message = 'Debe ingresar una versión'
      return
    } else if (this.version.new.toUpperCase() === this.version.edit?.name.toUpperCase()) {
      this.version.message = 'Debe ingresar un nombre distinto al actual'
      return
    } else if (this.versions.some(version => version.name.toUpperCase() === this.version.new.toUpperCase())) {
      this.version.message = 'Ya existe una versión con ese nombre'
      return
    } else {
      this.version.message = ''
    }
    this.version.openAlert = false
    const model = this.models.find(model => model.name === this.formData.model)

    await this.pushData({
      model: 'Version',
      fields: {
        id: this.version.edit?.id,
        id_model: model?.id,
        name: this.version.new.toUpperCase(),
      },
    })
    this.updateModel = !this.updateModel
    Object.assign(this.formData.version, [])
    this.formData.update = !this.formData.update
  }

  closeEdit () {
    this.brand = {
      openAlert: false,
      title: '',
      message: '',
      edit: null,
      new: '',
    }

    this.model = {
      openAlert: false,
      title: '',
      message: '',
      edit: null,
      new: '',
    }

    this.version = {
      openAlert: false,
      title: '',
      message: '',
      edit: null,
      new: '',
    }
  }

  get formFieldsColumn () {
    const { isBrandFilled, isModelFilled, isVersionSelected } = this

    if (isBrandFilled && !isModelFilled && !isVersionSelected) {
      return 4
    }

    return 3
  }

  isValue (str) {
    return str?.length
  }

  removeBrandAlias () {
    this.form.aliasBrand = null
    if (this.aliasBrand?.id) {
      this.removeData({
        model: 'Alias',
        fields: { id: this.aliasBrand.id },
      })
    }
    this.aliasBrand = null
  }

  removeModelAlias () {
    this.form.aliasModel = null
    if (this.aliasModel?.id) {
      this.removeData({
        model: 'Alias',
        fields: { id: this.aliasModel?.id },
      })
    }
    this.aliasModel = null
  }

  get disabledSend () {
    const { isLinkValid, form: { linkBrand, linkModel }, formData: { withoutPrice, withoutPhoto, rotation } } = this

    if (linkBrand?.length && !isLinkValid(linkBrand)) {
      return true
    }

    return Boolean(linkModel?.length && !isLinkValid(linkModel)) || withoutPhoto || withoutPrice || rotation
  }

  get displayAutoDetails () {
    const { auto } = this

    return auto?.version?.id || auto?.generation?.id
  }

  @Watch('auto', { immediate: true, deep: true })
  async onAutoChange (val) {
    if (!val?.version?.id) return

    const photo = await this.fetchData({
      query: { name: 'find', model: 'FileProcess' },
      filter: {
        _and: [
          { id_process_record: { _eq: val?.version?.id } },
          { parameter: { process: { id: { _eq: this.idProcess } } } },
          { parameter: { file_type: { name: { _eq: 'photo' } } } },
        ],
      },
      force: true,
    })

    this.photo = photo?.[0]
  }

  get linkModelIsNotEmpty () {
    const { form: { linkModel } } = this
    return Boolean(linkModel?.length)
  }

  addBrand () {
    this.brand.openAlert = true
    this.brand.title = 'Agrega una marca a la lista'
  }

  get someRequired () {
    const { form: { amount, amountFinanced, backupPhoto } } = this

    return Boolean(backupPhoto?.length) || Boolean(isValidNumber(amount) || isValidNumber(amountFinanced))
  }

  editBrand (val) {
    if (!val?.length) {
      this.addBrand()
      return
    }
    const brand = this.brands.find(brand => brand.name === val)
    this.brand.title = 'Al editar el nombre de la marca se verá reflejado en todos los autos asociados'
    this.brand.openAlert = true
    this.brand.edit = brand
    this.brand.new = brand.name
  }

  async confirmEditBrand () {
    if (!this.brand.new?.length) {
      this.brand.message = 'Debe ingresar una marca'
      return
    } else if (this.brand.new.toUpperCase() === this.brand.edit?.name.toUpperCase()) {
      this.brand.message = 'Debe ingresar un nombre distinto al actual'
      return
    } else if (this.brands.some(brand => brand.name.toUpperCase() === this.brand.new.toUpperCase())) {
      this.brand.message = 'Ya existe una marca con ese nombre'
      return
    } else {
      this.brand.message = ''
    }
    this.brand.openAlert = false

    await this.pushData({
      model: 'Brand',
      fields: {
        id: this.brand.edit?.id,
        name: this.brand.new.toUpperCase(),
      },
    })
    this.updateBrand = !this.updateBrand
    this.formData.brand = this.brand.new.toUpperCase()
    this.formData.update = !this.formData.update
  }

  buildYearFilter (formData) {
    if (!isValidNumber(formData.startYear) && !isValidNumber(formData.endYear)) return null

    if (formData.startYear !== null && !isValidNumber(formData.endYear)) return { years: { id_year: { _gte: formData.startYear } } }
    if (!isValidNumber(formData.startYear) && formData.endYear !== null) return { years: { id_year: { _lte: formData.endYear } } }

    return { years: { id_year: { _gte: formData.startYear, _lte: formData.endYear } } }
  }

  async setVersion (version) {
    this.formData.version = [version.id]

    const filteredYears = this.buildYearFilter(this.formData)

    this.versions = await this.fetchData({
      query: {
        name: 'find',
        model: 'Version',
        order: {
          name: 'asc',
          id: 'asc',
        },
      },
      filter: {
        _and: [
          { model: { name: { _eq: this.formData.model } } },
          { model: { brand: { name: { _eq: this.formData?.brand } } } },
          ...(filteredYears ? [filteredYears] : []),
        ],
      },
      force: true,
    })
  }

  clean () {
    this.formData.withoutPhoto = false
    this.formData.withoutPrice = false
    this.formData.fuel = ''
    this.formData.transmission = []
    this.formData.rotation = false
  }

  get versionIcon () {
    const { formData: { version }, isVersionSelected } = this

    if (!isVersionSelected) {
      return 'mdi-plus-circle-outline'
    }

    if (version?.length === 1) {
      return 'mdi-pencil'
    }

    return ''
  }

  @Watch('formData.version', { immediate: true, deep: true })
  async onVersionChangeSelection (val) {
    if (!val) return

    if (typeof val === 'string') {
      this.formData.version = [parseInt(val)]
    } else if (val?.length && val.some(item => typeof item === 'string')) {
      this.formData.version = val.map(item => parseInt(item))
    }
  }

  @Watch('formData', { immediate: true, deep: true })
  onFormDataChange () {
    this.versionYears = []
    this.setCursorPage(1)
  }

  @Watch('form.discontinued', { immediate: true })
  async onDiscontinuedChange (val) {
    if (val) {
      this.formVersionData.link = null
      this.formVersionData.amount = null
      this.formVersionData.amountFinanced = null
      this.formVersionData.backupPhoto = []
    }
  }

  get validYear () {
    const { formData: { startYear } } = this

    if (!startYear) return false
    return (dayjs().year() - 2) <= Number(startYear)
  }

  get isModelAllowed () {
    const { modelSelected } = this
    if (!modelSelected) return false

    const year = dayjs().year() - 2

    const isAllowed = modelSelected?.versionAggregateMinAndMax?.max >= year

    if (!isAllowed) {
      this.formVersionData.link = null
      this.formVersionData.amount = null
      this.formVersionData.amountFinanced = null
      this.formVersionData.backupPhoto = []
      this.form.discontinued = false
      this.form.modelPriceLink = null
    }

    return isAllowed
  }

  get watchFormDataModel () {
    const { formData: { model }, models } = this

    return {
      model,
      models,
    }
  }

  @Watch('watchFormDataModel', { immediate: true, deep: true })
  async onModelChangeSelection (val) {
    if (!val) return
    const match = val?.models?.find(model => model.name === val.model)

    if (!match) return

    const alias = await this.fetchData({
      query: { name: 'find', model: 'Alias' },
      filter: {
        _and: [
          { id_process_record: { _eq: match.id } },
          { id_person: { _eq: this.idChileautos } },
          { id_process: { _eq: this.idProcessModel } },
        ],
      },
      force: true,
    })
    this.aliasModel = alias?.[0]
    this.form.aliasModel = alias?.[0]?.name || null
    this.formData.rotation = false
    this.form.rotationIndex = null
    this.modelSelected = match
    this.form.modelPriceLink = match?.priceLink || null
    this.form.link = match?.priceLink || null
    this.form.linkModel = match?.officialWeb || null
  }

  get watchFormDataBrand () {
    const { formData: { brand }, brands } = this

    return {
      brand,
      brands,
    }
  }

  get modelIsDiscontinued () {
    const { versions } = this
    if (!versions?.length) return false

    const discontinued = versions?.every(version => version.discontinued)
    if (discontinued) {
      this.formData.modelDiscontinued = true
    }

    return discontinued
  }

  @Watch('watchFormDataBrand', { immediate: true, deep: true })
  async onBrandChangeSelection (val) {
    if (!val) return
    const match = val?.brands.find(brand => brand.name === val?.brand)

    if (!match) return

    const alias = await this.fetchData({
      query: { name: 'find', model: 'Alias' },
      filter: {
        _and: [
          { id_process_record: { _eq: match.id } },
          { id_person: { _eq: this.idChileautos } },
          { id_process: { _eq: this.idProcessBrand } },
        ],
      },
      force: true,
    })
    this.aliasBrand = alias?.[0]
    this.form.aliasBrand = alias?.[0]?.name || null
    this.form.linkBrand = match?.officialWeb || ''
    this.brandSelected = match
    this.form.vehicleGama = match?.gama || null
  }

  @Watch('cursor', { immediate: true, deep: true })
  onCursorChange (val) {
    this.searchVersionYear(val)
  }

  get someRequiredVersion () {
    const { formVersionData: { amount, amountFinanced, link, backupPhoto } } = this

    return Boolean(backupPhoto?.length) || Boolean(isValidNumber(amount) || isValidNumber(amountFinanced) || link?.length)
  }

  get displayPriceFields () {
    const { isVersionSelected, records, validYear, formData } = this
    return isVersionSelected && records.length && validYear && !formData.discontinued && !formData.modelDiscontinued
  }
  }
