
  import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
  import { ResourceHeaders } from '@/views/datatables/resources/roles'
  import SiiRow from '@/components/dataTables/sii/SiiRow.vue'
  import _ from 'lodash'
  import { Person } from '@/entities/persons'
  import SiiMultipleRow from '@/components/dataTables/sii/SiiMultipleRow.vue'
  import { VersionYear } from '@/entities/vehicle'
  import SiiSku from '@/components/dataTables/sii/SiiSku.vue'

@Component({
  components: { SiiSku, SiiMultipleRow, SiiRow },
})
  export default class BaseSiiTable extends Vue {
  @Prop({ type: Object, default: null }) readonly person!: Person;
  @Prop({ type: Array, default: () => [] }) readonly headers!: ResourceHeaders;
  @Prop({ type: Array, default: () => [] }) readonly items!: [];
  @Prop({ type: Array, default: () => [] }) readonly rotationIndex!: [];
  @Prop({ type: Number, default: 1 }) readonly page!: boolean;
  @Prop({ type: Boolean, default: false }) readonly modelIsDiscontinued!: boolean;
  @Prop({ type: Number, default: null }) readonly idProcess!: number;
  @Prop({ type: Number, default: null }) readonly idProcessMileage!: number;
  @Prop({ type: Boolean, default: false }) readonly displaySelector!: boolean;
  @Prop({ type: String, default: null }) readonly aliasBrand!: string;
  @Prop({ type: String, default: null }) readonly aliasModel!: string;
  @Prop({ type: String, default: null }) readonly linkModel!: string;
  @Prop({ type: Object, default: () => null }) photoMileageProperties!: Record<string, any>;
  @Prop({ type: Array, default: () => [] }) readonly backupPhoto!: Array<Record<string, any>>;

  selectedItems: any[] = [];
  expandedItems: any[] = [];

  toggleSelect (itemId: any): void {
    if (!itemId) return null

    const item = this.items.find((item: any) => item.id === itemId)

    const index = this.selectedItems.findIndex((item: any) => item.id === itemId)
    index > -1 ? this.selectedItems.splice(index, 1) : this.selectedItems.push(item)
  }

  selectAll (values) {
    if (values.value) {
      this.selectedItems = _.cloneDeep(this.items)
    } else {
      this.selectedItems = []
    }
  }

  clearSelection () {
    this.selectedItems = []
  }

  @Watch('selectedItems', { immediate: true })
  onSelectedItemsChange (val: any[]) {
    this.$emit('input', val)
  }

  @Watch('displaySelector', { immediate: true })
  onDisplaySelectorChange (val: boolean) {
    if (!val) {
      this.clearSelection()
    }
  }

  @Watch('items', { immediate: true, deep: true })
  onItemsChange (val: VersionYear[]) {
    if (val?.length) {
      this.expandedItems = val.filter(v => v.hasMultipleSKUs)
    }
  }
  }
