
  import { Component, Emit, Prop, Vue, Watch } from 'vue-property-decorator'
  import { CustomAttributes } from '@/entities/vehicle'
  import CellManager from '@/components/dataTables/cell/VersionManager/CellManager.vue'

@Component({
  components: { CellManager },
})
  export default class VersionCellManager extends Vue {
  @Prop({ type: Object, required: true }) attribute: CustomAttributes
  @Prop({ type: String, default: null }) version: string | null
  @Prop({ type: Boolean, default: false }) discontinued: boolean
  @Prop({ type: String, default: null }) value: string | null

  tab = 0

  get isDirect () {
    const { directAttributes } = this.attribute

    return Boolean(directAttributes)
  }

  get isMultiple () {
    const { hasMultipleSKUs } = this.attribute

    return Boolean(hasMultipleSKUs)
  }

  @Emit('input')
  changeTab (tab: number) {
    const { attribute } = this

    return attribute?.attrs?.[tab]?.sku
  }

  @Watch('tab', { immediate: true })
  onTabChange (tab: number) {
    this.changeTab(tab)
  }
  }
